<script setup type="module">
import { ref, inject, onMounted, onUnmounted, computed } from 'vue';
import { useClaimStore } from '@/stores/claim';
import { useOptionsStore } from '@/stores/options';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { useToast } from 'vue-toastification';

const toast = useToast();
const params = ref({
    term: '',
    role: null,
    status: null
});

const claimStore = useClaimStore();
const { searchClaims, destroyClaim, getClaimTypeString } = claimStore;
const { all } = storeToRefs(claimStore);

const optionsStore = useOptionsStore();
const { statuses, dealerships } = storeToRefs(optionsStore);

const userStore = useUserStore();
const { userProfile } = storeToRefs(userStore);

const getDealerships = computed(() => {
    if (userProfile.value.hasRole(['dealership_manager', 'fsm'])) {
        return dealerships.value.filter(dealership => userProfile.value.dealerships.some(userDealership => userDealership.id === dealership.id));
    }
    return dealerships.value;
});

const clearSearch = () => {
    params.value.term = '';
    search();
};

const tableUpdate = ({page, sortBy, itemsPerPage}) => {
    params.value.page = page;
    params.value.sort_by = sortBy;
    params.value.per_page = itemsPerPage;
    search();
};

const search = () => {
    searchClaims(params.value);
};

const deleteClaim = async (claim) => {
    const confirm = await modals.show('ConfirmationModal', {
        props: {
            title: 'Confirm Claim Removal',
            prompt: 'Delete ' + claim.name + '?'
        }
    });

    if (confirm) {
        destroyClaim({
            hash: claim.hash
        }).then(_ => {
            search();
        });
    }
};

const modals = inject('modals');
const openModal = (modal, mode = 'Create', claim = null) => {
    if (mode == 'Edit') {
        modals.show(modal, {
            props: {
                claim: {
                    ...claim,
                },
                mode: 'Edit'
            }
        }).then(_ => {
            search();
        });
    } else {
        modals.show(modal).then(_ => {
            search();
        });
    }
};

const getStatusColor = (status) => {
    if (status == 'New') {
        return 'blue';
    } else if (status == 'Accepted') {
        return 'green';
    } else {
        return 'red';
    }
};

onMounted(() => {
    window.Echo.private('test-web-sockets')
        .listen('TestWebSockets', (_) => {
            toast.success('Web socket event received!');
        });
});

onUnmounted(() => {
    window.Echo.leave('test-web-sockets');
});

</script>
<template>
<MainContentLayout>
    <template #breadcrumb>
        <BreadCrumb />
    </template>
    <template #title>
        {{ $t('label.claims') }}
    </template>
    <template #actions>
        <StyledButton @click="openModal('ClaimsModal')">
            <FontAwesomeIcon
                icon="plus"
                class="mr-1" />
            {{ $t('label.add_claim') }}
        </StyledButton>
    </template>
    <div class="grid md:grid-cols-3 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="search"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDropdown
            v-model="params.status"
            name="status"
            item-title="name"
            item-value="id"
            :options="statuses"
            :label="$t('label.status')"
            @input="search" />
        <StyledDropdown
            v-model="params.dealership"
            name="dealership"
            item-title="name"
            item-value="id"
            :options="getDealerships"
            :label="$t('label.dealership')"
            @input="search" />
    </div>

    <DataTable
        v-if="all"
        :data="all"
        :headers="[
            { title: $t('label.certificate'), key: 'certificate_number' },
            { title: $t('label.claim_date'), key: 'claim_date' },
            { title: $t('label.first_and_last_name'), key: 'first_and_last_name', value: item => `${item.first_name} ${item.last_name}` },
            { title: $t('label.amount'), key: 'amount' },
            { title: $t('label.type'), key: 'type', value: item => getClaimTypeString(item) },
            { title: $t('label.status'), key: 'status_name' },
            { title: $t('label.dealership'), key: 'dealership_name' },
            { title: $t('label.wo_number'), key: 'work_order_number' },
            { title: $t('label.contact_info'), key: 'contact_info', sortable: false },
            { title: $t('label.description'), key: 'description', sortable: false },
        ]"
        :actions="[
            { title: $t('label.edit'), icon: 'user-edit', action: (item) => openModal('ClaimsModal', 'Edit', item) },
            { title: $t('label.delete'), icon: 'user-times', action: (item) => deleteClaim(item) },
        ]"
        @table-update="tableUpdate">
        <template #[`item.status_name`]="{ item }">
            <vChip :color="getStatusColor(item.status_name)">{{ item.status_name }}</vChip>
        </template>
        <template #[`item.contact_info`]="{ item }">
            <div>{{ item.contact_number }}</div>
            <div>{{ item.contact_email }}</div>
        </template>
    </DataTable>

</MainContentLayout>
</template>

