<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref } from 'vue';
import { useCertificateStore } from '@/stores/certificate';
import { handleError } from '@/mixins/ErrorHandlingMixin';

const props = defineProps({
    valueBundle: {
        type: Object,
        default: () => ({
            name: null,
            fee_total: 0,
            remittance_fee_total: 0,
            is_tax_edited: false,
            gst_total: 0,
            pst_total: 0
        })
    }
});

const emit = defineEmits(['close-modal']);
const form = ref({ ...props.valueBundle });

const certificateStore = useCertificateStore();
const { updateValueBundleCharges } = certificateStore;

const gst = props.valueBundle?.gst_rate;
const gstRate = gst ? gst / 100 : 0;
const pst = props.valueBundle?.pst_rate;
const pstRate = pst ? pst / 100 : 0;

// If the taxes calculated are not equal to the taxes entered by the user, unlock the taxes section
if (
    (Number(form.value.fee_total) * gstRate).toFixed(2) != Number(form.value.gst_total).toFixed(2)
    || (Number(form.value.fee_total) * pstRate).toFixed(2) != Number(form.value.pst_total).toFixed(2)
) {
    form.value.is_tax_edited = true;
}

const save = async () => {
    try {
        await updateValueBundleCharges({
            ...form.value,
            hash: props.valueBundle.hash,
        });
        emit('close-modal');
    } catch (error) {
        handleError(error);
    }
};

const resetValues = () => {
    form.value.fee_total = Number(props.valueBundle.remittance_fee_total);
    form.value.gst_total = Number(props.valueBundle.remittance_fee_total * gstRate);
    form.value.pst_total = Number(props.valueBundle.remittance_fee_total * pstRate);
    form.value.is_tax_edited = false;
};

const handleReset = () => {
    resetValues();
};

const calculateTaxes = () => {
    if (!form.value.is_tax_edited) {
        form.value.gst_total = (Number(form.value.fee_total) * Number(gstRate)).toFixed(2) ?? 0;
        form.value.pst_total = (Number(form.value.fee_total) * Number(pstRate)).toFixed(2) ?? 0;
    }
};

const editTaxes = () => {
    form.value.is_tax_edited = true;
};

const clearTaxes = () => {
    form.value.is_tax_edited = false;
    calculateTaxes();
};
</script>
<template>
<ModalWrapper>
    <template #header>Value Bundle</template>
    <template #subheader>Update value bundle charges</template>

    <form @submit.prevent="save">
        <div>
            <StyledInput
                v-model="form.name"
                name="name"
                :placeholder="$t('label.name')"
                :label="$t('label.name')" />
            <StyledCurrency
                v-model="form.remittance_fee_total"
                color="primary"
                placeholder="0.00"
                name="fee_total"
                :label="$t('label.remittance_total')"
                disabled />
            <StyledCurrency
                v-model="form.fee_total"
                color="primary"
                placeholder="0.00"
                name="fee_total"
                :label="$t('label.fee_total')"
                @update:modelValue="calculateTaxes" />
            <div class="text-right">
                <div
                    v-if="!form.is_tax_edited"
                    class="underline hover:cursor-pointer text-blue-500 hover:text-blue-700"
                    @click="editTaxes">
                    Edit Taxes
                </div>
                <div
                    v-else
                    class="underline hover:cursor-pointer text-orange-700 hover:text-orange-900"
                    @click="clearTaxes">
                    Reset Tax Calculation
                </div>
            </div>
            <StyledCurrency
                v-model="form.gst_total"
                color="primary"
                placeholder="0.00"
                name="gst_total"
                :label="$t('label.gst_hst_total')"
                :disabled="!form.is_tax_edited" />
            <StyledCurrency
                v-model="form.pst_total"
                color="primary"
                placeholder="0.00"
                name="pst_total"
                :label="$t('label.pst_total')"
                :disabled="!form.is_tax_edited" />
        </div>
    </form>

    <template #footer>
        <StyledButton
            color="error"
            @click="handleReset">
            Reset Values
        </StyledButton>
        <StyledSaveButton modal @save="save" />
    </template>
</ModalWrapper>
</template>
