<script setup>
import { ref, watch, defineExpose, inject } from 'vue';
import { router } from '@inertiajs/vue3';
import { useOptionsStore } from '@/stores/options';
import { useCertificateStore } from '@/stores/certificate';
import { storeToRefs } from 'pinia';
import { getYear } from 'date-fns';
import { handleError } from '@/mixins/ErrorHandlingMixin';
import { normalize } from '@/utils/vueUtils';

const props = defineProps({
    readOnly: {
        type: Boolean,
        default: false
    }
});

const optionsStore = useOptionsStore();
const { vehicleTypes } = storeToRefs(optionsStore);

const { active, isVehicleModified } = storeToRefs(useCertificateStore());
const certificateStore = useCertificateStore();
const { saveVehicle, decodeVin, setVehicleModified } = certificateStore;
const modals = inject('modals');

const form = ref({
    hash: null,
    vehicle: {
        type: null,
        vin: null,
        make: null,
        model: null,
        year: null,
        odometer: null,
        stock_number: null,
    }
});

const decodedMake = ref(active.value.vehicle?.make);
const decodedModel = ref(active.value.vehicle?.model);
const decodedYear = ref(active.value.vehicle?.year);

// Check if active has vehicle and update form.vehicle if needed
if (active.value?.vehicle) {
    form.value.vehicle = {
        ...active.value.vehicle
    };
}

const save = async () => {
    try {
        await saveVehicle({
            ...form.value,
            hash: active.value.hash,
        });
        decodedMake.value = form.value.vehicle.make;
        decodedModel.value = form.value.vehicle.model;
        decodedYear.value = form.value.vehicle.year;
    } catch (error) {
        handleError(error);
    }
};

const decode = async () => {
    try {
        const { data } = await decodeVin({ vin: form.value.vehicle.vin });
        if (data?.vin_lookup) {
            form.value.vehicle = {
                ...form.value.vehicle,
                make: data.vin_lookup.make,
                model: data.vin_lookup.model,
                year: data.vin_lookup.year,
            };
            decodedMake.value = data.vin_lookup.make;
            decodedModel.value = data.vin_lookup.model;
            decodedYear.value = data.vin_lookup.year;

            vehicleTypeRule();
        }
    } catch (error) {
        handleError(error);
    }
};

const vehicleTypeRule = () => {
    const currentYear = getYear(new Date());
    const vehicleAge = currentYear - form.value.vehicle.year;
    if (vehicleAge <= 1 && form.value.vehicle.odometer <= 10000) {
        form.value.vehicle.type = 'new';
    }

    if (vehicleAge > 1 || form.value.vehicle.odometer > 10000) {
        form.value.vehicle.type = 'used';
    }
};

const clear = () => {
    form.value.vehicle = {
        ...form.value.vehicle,
        make: null,
        model: null,
        year: null,
        odometer: null,
        stock_number: null,
    };
};

const cancel = () => {
    router.visit('/certificates');
};

const cancelChanges = async () => {
    if (!isVehicleModified.value) {
        cancel();
        return;
    }

    const confirm = await modals.show('ConfirmationModal', {
        props: {
            title: 'Confirm',
            prompt: 'Are you sure you want to cancel? Any unsaved changes will be lost.'
        }
    });

    if (confirm) {
        cancel();
    }
};

const setActiveVehicle = (certificate) => {
    form.value.vehicle = {
        ...certificate.vehicle
    };
};

watch (() => active.value, () => {
    setActiveVehicle(active.value);
});

watch([
    () => form.value.vehicle.year,
    () => form.value.vehicle.odometer
], vehicleTypeRule);

watch(() => form.value.vehicle, (newVal) => {
    const normalizedNewVal = normalize(newVal);
    const normalizedActiveVal = normalize(active.value.vehicle);
    const checkVehicle = JSON.stringify(normalizedNewVal) !== JSON.stringify(normalizedActiveVal);
    setVehicleModified(checkVehicle);
}, { deep: true });

defineExpose({
    form,
    save
});
</script>

<template>
<div>
    <h4 class="h6 pl-1 mb-2">{{ $t('label.vehicle_information') }}</h4>
    <div class="grid grid-cols-2 gap-3">
        <StyledDropdown
            v-model="form.vehicle.type"
            name="type"
            item-title="name"
            item-value="value"
            :options="vehicleTypes"
            :label="$t('label.type')"
            disabled />
        <StyledInput
            v-model="form.vehicle.vin"
            :label="$t('label.vin')"
            name="vehicle.vin"
            :placeholder="$t('label.vin')"
            :disabled="props.readOnly" />
    </div>
    <div class="mb-6">
        <StyledButton class="mr-2" :disabled="props.readOnly" @click="decode">{{ $t('label.decode') }}</StyledButton>
        <StyledButton :disabled="props.readOnly" @click="clear">{{ $t('label.clear') }}</StyledButton>
    </div>
    <div class="grid grid-cols-2 gap-3">
        <StyledInput
            v-model="form.vehicle.make"
            :label="$t('label.make')"
            name="vehicle.make"
            :placeholder="$t('label.make')"
            :disabled="!!decodedMake" />
        <StyledInput
            v-model="form.vehicle.model"
            :label="$t('label.model')"
            name="vehicle.model"
            :placeholder="$t('label.model')"
            :disabled="!!decodedModel" />
        <StyledInput
            v-model="form.vehicle.year"
            type="number"
            :label="$t('label.year')"
            name="vehicle.year"
            :placeholder="$t('label.year')"
            :disabled="!!decodedYear" />
        <StyledInput
            v-model="form.vehicle.odometer"
            type="number"
            :label="$t('label.odometer')"
            name="vehicle.odometer"
            :placeholder="$t('label.odometer')"
            :disabled="props.readOnly" />
        <StyledInput
            v-model="form.vehicle.stock_number"
            :label="$t('label.stock_number')"
            name="vehicle.stock_number"
            :placeholder="$t('label.stock_number')"
            :disabled="props.readOnly" />
    </div>
    <StyledSaveButton v-if="!props.readOnly" @save="save" @cancel="cancelChanges" />
</div>
</template>
