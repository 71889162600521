<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useLenderStore } from '@/stores/lender';
import { useOptionsStore } from '@/stores/options';
import { handleError } from '@/mixins/ErrorHandlingMixin';

const props = defineProps({
    lender: {
        type: Object,
        default: () => ({
            name: null,
            address: null,
            address2: null,
            city: null,
            postal: null,
            province_id: null,
            contact_number: null,
            type: null,
            is_active: true,
        })
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const emit = defineEmits(['close-modal']);
const form = ref({ ...props.lender });
const toast = useToast();
const { provinces } = useOptionsStore();
const types = [
    { name: 'Bank', value: 'bank' },
    { name: 'Private', value: 'private' },
];

const lenderStore = useLenderStore();
const { storeLender, updateLender } = lenderStore;

const save = async () => {
    try {
        const action = props.mode === 'Create' ? storeLender : updateLender;
        const toastMessage = props.mode === 'Create' ? 'Lender Created' : 'Lender Updated';

        await action({
            ...form.value,
            hash: props.lender.hash,
        });

        toast.success(toastMessage);
        emit('close-modal');
    } catch (error) {
        handleError(error);
    }
};
</script>
<template>
<ModalWrapper>
    <template #header>{{ mode }} Lender</template>
    <template #subheader>Create or edit a lender</template>

    <form @submit.prevent="save">
        <div class="grid md:grid-cols-2 gap-x-4 gap-y-3">
            <StyledInput
                v-model="form.name"
                name="name"
                :placeholder="$t('label.name')"
                :label="$t('label.name')" />
            <StyledDropdown
                v-model="form.province_id"
                item-title="name"
                item-value="id"
                :options="provinces"
                name="province_id"
                :placeholder="$t('label.please_select')"
                :label="$t('label.province')" />
            <StyledInput
                v-model="form.address"
                name="address"
                :placeholder="$t('label.address')"
                :label="$t('label.address')" />
            <StyledInput
                v-model="form.address2"
                name="address2"
                :placeholder="$t('label.address_line_2')"
                :label="$t('label.address_line_2')" />
            <StyledInput
                v-model="form.city"
                name="city"
                :placeholder="$t('label.city')"
                :label="$t('label.city')" />
            <StyledMask
                v-model="form.postal"
                name="postal"
                placeholder="A1A 1A1"
                :options="{
                    mask: '@#@ #@#',
                    tokens: {
                        '#': { pattern: /\d/ },
                        '@': { pattern: /[a-zA-Z]/, transform: v => v.toUpperCase()}
                    }
                }"
                :label="$t('label.postal')" />
            <StyledMask
                v-model="form.contact_number"
                :mask="['###-###-####']"
                :label="$t('label.contact_number')"
                name="contact_number"
                placeholder="###-###-####" />
            <StyledDropdown
                v-model="form.type"
                item-title="name"
                item-value="value"
                :options="types"
                name="type"
                :placeholder="$t('label.please_select')"
                :label="$t('label.type')" />
            <StyledRoundSwitch
                v-model="form.is_active"
                label="Is Active"
                inset
                name="is_active" />
        </div>
    </form>

    <template #footer>
        <StyledSaveButton modal @save="save" />
    </template>
</ModalWrapper>
</template>
