<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref, inject } from 'vue';
import { useToast } from 'vue-toastification';
import { useCertificateStore } from '@/stores/certificate';
import { useRemittanceStore } from '@/stores/remittance';
import { useOptionsStore } from '@/stores/options';
import { handleError } from '@/mixins/ErrorHandlingMixin';
import { storeToRefs } from 'pinia';
import { formatAmount } from '@/utils/formatHelper';
import { format } from 'date-fns';
import StyledVDatepicker from '@/components/StyledVDatepicker';

const modals = inject('modals');
const props = defineProps({
    remittance: {
        type: Object,
        default: () => ({
            global_status_id: null,
            remittance_sub_total: 0,
            tax_total: 0,
            remittance_total: 0,
            note: '',
            dealership_id: null,
            certificates: []
        })
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const params = ref({
    term: '',
    status: null,
    remittanceStatus: null,
    purchaseDate: null,
    dealership: null,
    loading: false,
    endDate: format(new Date(), 'yyyy-MM-dd'),
    fromCreateOrEditModal: true,
    remittance_id: props.remittance.id,
    fetchAll: false
});

const emit = defineEmits(['close-modal']);
const form = ref({
    dealership: props.remittance.dealership_id,
    remittance_total: 0,
    certificates: JSON.parse(JSON.stringify(props.remittance.certificates.filter(cert => !cert.pivot?.is_refund))),
    refunded_certificates: JSON.parse(JSON.stringify(props.remittance?.refunded_certificates ?? [])),
    note: props.remittance.note
});

const filters = ref({
    endDate: new Date()
});

const loading = ref(false);

const toast = useToast();

const { dealerships } = useOptionsStore();

const certificateStore = useCertificateStore();
const { searchCertificates, getCustomerName, getFsmName } = certificateStore;
const { all: allCertificates, allCerts } = storeToRefs(certificateStore);

const remittanceStore = useRemittanceStore();
const { storeRemittance, updateRemittance } = remittanceStore;

const save = async () => {
    try {
        const action = props.mode === 'Create' ? storeRemittance : updateRemittance;
        const toastMessage = props.mode === 'Create' ? 'remittance Created' : 'remittance Updated';

        await action({
            ...form.value,
            hash: props.remittance.hash,
        });

        toast.success(toastMessage);
        emit('close-modal');
    } catch (error) {
        handleError(error);
    }
};

const getCertificates = () => {
    loading.value = true;
    params.value.fetchAll = props.mode === 'Create';
    searchCertificates(params.value).then(() => {
        setTimeout(() => {
            if (props.mode === 'Create') {
                form.value.certificates = [...allCerts.value.filter(cert => !cert.pivot?.is_refund)];
            }
            loading.value = false;
            params.value.fetchAll = false;
        }, 500);
    });
};

const onSelectDealership = () => {
    getCertificates();
};

const onSelectEndDate = () => {
    params.value.endDate = format(filters.value.endDate, 'yyyy-MM-dd');
    getCertificates();
};

const isCertificateSelected = (item) => {
    return !!form.value.certificates.find(cert => cert.id === item.id);
};

if (props.remittance.dealership_id) {
    onSelectDealership();
}

const search = () => {
    searchCertificates(params.value);
};

const tableUpdate = ({page, sortBy, itemsPerPage}) => {
    if (loading.value) {
        return;
    }
    params.value.page = page;
    params.value.sort_by = sortBy;
    params.value.per_page = itemsPerPage;
    search();
};

const onSelectCertificate = (certificate) => {
    const index = form.value.certificates.findIndex(cert => cert.id === certificate.id);
    if (index > -1) {
        form.value.certificates.splice(index, 1);
    } else {
        form.value.certificates.push(certificate);
    }
};

const calculateRemittanceTotal = () => {
    return form.value?.certificates?.reduce((acc, cert) => acc + Number(cert.remittance_total), 0);
};

const calculateRefundTotal = () => {
    return form.value?.refunded_certificates?.reduce((acc, cert) => acc + Number(cert.remittance_total), 0);
};

const showRefundedCertificates = () => {
    modals.show('RefundedCertificatesModal', {
        props: {
            certificates: props.remittance.refunded_certificates,
        }
    });
};
</script>
<template>
<ModalWrapper>
    <template #header>{{ mode }} remittance</template>
    <template #subheader>Create or edit a remittance</template>

    <div class="grid grid-cols-2 gap-3">
        <StyledDropdown
            v-model="form.dealership"
            name="dealership"
            item-title="name"
            item-value="id"
            :options="dealerships"
            :label="$t('label.dealership')"
            @input="onSelectDealership" />
        <StyledVDatepicker
            v-if="form.dealership"
            v-model="filters.endDate"
            name="end_date"
            :label="$t('label.end_date')"
            @input="onSelectEndDate" />
    </div>

    <div v-if="form.dealership && allCertificates">
        <DataTable
            :data="allCertificates"
            :headers="[
                { title: $t('label.select'), key: 'select', sortable: false },
                { title: $t('label.cert_no'), key: 'certificate_number' },
                { title: $t('label.customer_name'), key: 'customer_name', value: item => getCustomerName(item)  },
                { title: $t('label.remittance_total'), key: 'remittance_total', value: item => formatAmount(item.remittance_total) },
                { title: $t('label.dealership'), key: 'dealership_id', value: item => item.dealership.name },
                { title: $t('label.fsm'), key: 'fsm', value: item => getFsmName(item) },
                { title: $t('label.purchase_date'), key: 'purchase_date' },
            ]"
            @table-update="tableUpdate">
            <template #[`item.select`]="{ item }">
                <div class="flex items-center">
                    <StyledCheckbox
                        type="checkbox"
                        :model-value="isCertificateSelected(item)"
                        class="hide-details"
                        @input="onSelectCertificate(item)" />
                </div>
            </template>
        </DataTable>

        <div class="py-3">
            <StyledTextArea
                v-model="form.note"
                name="note"
                :rows="3"
                :label="$t('label.note')"
                :placeholder="$t('label.note')" />
        </div>

        <div class="border rounded p-3 shadow-lg">
            <div class="flex py-1 border-b">
                <div class="w-48 border-r">
                    Remittance Total:
                </div>
                <div class="pl-3">
                    {{ formatAmount(calculateRemittanceTotal()) }}
                </div>
            </div>
            <div v-if="calculateRefundTotal()" class="flex py-1 border-b">
                <div class="w-48 border-r">
                    <button
                        class="underline font-medium hover:text-grey-600"
                        @click="showRefundedCertificates">
                        Refund Credited ({{ form.refunded_certificates.length }} Certs):
                    </button>
                </div>
                <div class="pl-3">
                    -{{ formatAmount(calculateRefundTotal()) }}
                </div>
            </div>
            <div class="flex py-1 border-b">
                <div class="w-48 border-r">
                    Carry Forward Balance:
                </div>
                <div class="pl-3">
                    $0.00
                </div>
            </div>
            <div class="flex py-1">
                <div class="w-48 border-r">
                    Total Owed:
                </div>
                <div class="pl-3">
                    {{ formatAmount(calculateRemittanceTotal() - calculateRefundTotal()) }}
                </div>
            </div>
        </div>
    </div>

    <template #footer>
        <StyledSaveButton modal :title="props.mode == 'Create' ? 'Generate Remittance' : 'Update Remittance'" @save="save" />
    </template>
</ModalWrapper>
</template>
