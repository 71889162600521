<script setup>
import { ref, watch, computed } from 'vue';
import { useCertificateStore } from '@/stores/certificate';
import { storeToRefs } from 'pinia';

const { active } = storeToRefs(useCertificateStore());

const form = ref({
    hash: null,
    sub_total: 0,
    gst_total: 0,
    pst_total: 0,
    fee_total: 0,
    remittance_total: 0,
    dealer_due: 0,
});

const setActiveCertificate = () => {
    const gstTotal = isTaxExempt.value ? 0 : Number(active.value.gst_total);
    const pstTotal = isTaxExempt.value ? 0 : Number(active.value.pst_total);
    const feeTotal = isTaxExempt.value
        ? Number(active.value.fee_total) - Number(active.value.gst_total) - Number(active.value.pst_total)
        : Number(active.value.fee_total);

    form.value = {
        ...active.value,
        sub_total: Number(active.value.sub_total),
        gst_total: gstTotal,
        pst_total: pstTotal,
        fee_total: feeTotal
    };
};

const amountPaid = computed(() => {
    if (!active.value || Object.keys(active.value).length === 0) {
        return 0;
    }
    if (active.value.remittances.length === 0) {
        return 0;
    }
    const remittance = active.value.remittances[0];
    return remittance.payments.reduce((acc, payment) => acc + Number(payment.payment_amount), 0);
});

const remaining = computed(() => {
    if (!active.value || Object.keys(active.value).length === 0 || active.value.remittances?.length === 0) {
        return 0;
    }
    const remittance = active.value.remittances[0];
    return Number(remittance.remittance_total) - amountPaid.value;
});

const valueBundle = computed(() => active.value?.certificate_bundle ?? null);

const products = computed(() => {
    if (!active.value || Object.keys(active.value).length === 0) {
        return [];
    }

    let productList = active.value.certificate_products || [];

    if (active.value.bundle) {
        // Remove bundle products from the list
        const bundleProductIds = active.value?.bundle?.bundle_products?.map(bundleProduct => bundleProduct.product_id) ?? [];
        productList = productList.filter(product => !bundleProductIds.includes(product.product_id));

        // Add value bundle as a product
        const newProduct = {
            hash: valueBundle.value?.hash ?? null,
            name: 'Value Bundle',
            fee_total: valueBundle.value?.fee_total ?? 0,
            certificate_product_fields: [{ enabled: true }]
        };

        productList.push(newProduct);
    }

    return productList;
});

const dealerProfit = computed(() => {
    if (!active.value || Object.keys(active.value).length === 0) {
        return form.value.sub_total;
    }
    const profit = Number(form.value.sub_total) - Number(form.value.remittance_total);
    const formatter = new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
    });
    return formatter.format(profit);
});

const isTaxExempt = computed(() => {
    return !!active.value?.buyers?.find(buyer => buyer.treaty_number);
});

// Check if active has totals and update form if needed
if (active.value) {
    setActiveCertificate();
}

watch(() => active.value, () => {
    if (active.value) {
        setActiveCertificate();
    }
});
</script>

<template>
<div>
    <div class="mb-4 pl-1">
        <h4 class="h6">{{ $t('label.certificate_totals') }}</h4>
    </div>
    <div class="certificate-totals">
        <div class="border border-grey-500 rounded shadow-lg px-3 pt-3 mb-5">
            <div class="border-b mb-3">Products</div>
            <template v-for="product in products" :key="product.hash">
                <StyledCurrency
                    v-if="product.certificate_product_fields.length"
                    :model-value="Number(product.fee_total)"
                    color="primary"
                    placeholder="0.00"
                    :name="`${product.id}_sub_total`"
                    :label="$t(`label.${product.name.toLowerCase().replaceAll(' ', '_')}`)"
                    disabled />
            </template>
        </div>
        <StyledCurrency
            :model-value="Number(form.sub_total)"
            color="primary"
            placeholder="0.00"
            name="sub_total"
            :label="$t('label.sub_total')"
            disabled />
        <StyledCurrency
            :model-value="Number(form.gst_total)"
            :class="{ 'disabled-full-opacity': true }"
            color="primary"
            placeholder="0.00"
            name="gst_total"
            :label="$t('label.gst_hst_total')"
            disabled />
        <StyledCurrency
            :model-value="Number(form.pst_total)"
            color="primary"
            placeholder="0.00"
            name="pst_total"
            :label="$t('label.pst_total')"
            disabled />
        <StyledCurrency
            :model-value="Number(form.fee_total)"
            color="primary"
            placeholder="0.00"
            name="fee_total"
            :label="$t('label.fee_total')"
            disabled />
        <StyledCurrency
            :model-value="Number(form.remittance_total)"
            color="primary"
            placeholder="0.00"
            name="remittance_total"
            :label="$t('label.remittance_total')"
            disabled />
        <StyledCurrency
            :model-value="Number(remaining)"
            color="primary"
            placeholder="0.00"
            name="dealer_due"
            :label="$t('label.dealer_due')"
            disabled />
        <StyledInput
            :model-value="dealerProfit"
            color="primary"
            placeholder="0.00"
            name="dealer_due"
            :label="$t('label.dealer_profit')"
            disabled />
    </div>
</div>
</template>

