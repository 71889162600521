<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref, computed } from 'vue';
import { useToast } from 'vue-toastification';
import { useCertificateStore } from '@/stores/certificate';
import { useRemittanceStore } from '@/stores/remittance';
import { handleError } from '@/mixins/ErrorHandlingMixin';
import { useDateFormatter } from '@/composables/dateFormatter';
import { formatAmount } from '@/utils/formatHelper';

const props = defineProps({
    remittance: {
        type: Object,
        required: true
    },
    dealership: {
        type: Object,
        required: true
    },
    readonly: {
        type: Boolean,
        default: false
    }
});

const { formatDateLocal } = useDateFormatter();
const emit = defineEmits(['close-modal']);
const form = ref({
    payment_reference: '',
    payment_amount: ''
});

const certificates = ref({
    data: props.remittance.certificates,
    total: props.remittance.certificates.length,
    per_page: 100
});

const amountPaid = computed(() => {
    return props.remittance.payments.reduce((acc, payment) => acc + Number(payment.payment_amount), 0);
});

const remaining = computed(() => {
    return Number(props.remittance.remittance_total) - amountPaid.value;
});

const toast = useToast();

const certificateStore = useCertificateStore();
const { getCustomerName, getFsmName } = certificateStore;

const remittanceStore = useRemittanceStore();
const { applyPayment } = remittanceStore;

const save = async () => {
    try {
        await applyPayment({
            ...form.value,
            hash: props.remittance.hash,
        });

        toast.success('Payment applied successfully.');
        emit('close-modal');
    } catch (error) {
        handleError(error);
    }
};

const getCertificateTotal = (certificate) => {
    const subSign = certificate?.pivot?.is_refund ? '-' : '';
    return subSign + formatAmount(certificate.remittance_total ?? 0);
};
</script>
<template>
<ModalWrapper>
    <template #header>{{ readonly ? 'View Payments' : 'Apply Payment' }}</template>

    <form @submit.prevent="save">
        <div v-if="certificates">
            <DataTable
                :data="certificates"
                :headers="[
                    { title: $t('label.remittance_id'), key: 'certificate_id', value: () => props.remittance.id },
                    { title: $t('label.cert_no'), key: 'certificate_number' },
                    { title: $t('label.customer_name'), key: 'customer_name', value: item => getCustomerName(item)  },
                    { title: $t('label.remittance_total'), key: 'remittance_total', value: item => getCertificateTotal(item) },
                    { title: $t('label.dealership'), key: 'dealership_id', value: () => props.dealership.name },
                    { title: $t('label.fsm'), key: 'fsm', value: item => getFsmName(item) },
                    { title: $t('label.purchase_date'), key: 'purchase_date' },
                ]">
                <template #[`item.select`]="{ item }">
                    <div class="flex items-center">
                        <StyledCheckbox
                            type="checkbox"
                            :model-value="isCertificateSelected(item)"
                            class="hide-details"
                            @input="onSelectCertificate(item)" />
                    </div>
                </template>
                <template #bottom />
            </DataTable>

            <div v-if="props.remittance.note" class="mt-3 px-3 py-2 border border-grey-500 rounded shadow-lg bg-blue-100">
                <div class="border-b border-grey-500 pb-1 font-bold">Notes</div>
                <div class="pt-1">{{ props.remittance.note }}</div>
            </div>

            <div class="mt-3 px-3 py-2 border border-grey-500 rounded shadow-lg">
                <div>
                    <div class="flex space-x-3 font-bold border-b py-1">
                        <div class="w-1/3">Accounting Reference</div>
                        <div class="w-1/3">Amount Paid</div>
                        <div class="w-1/3">Paid At</div>
                    </div>
                    <div class="max-h-36 overflow-y-auto">
                        <div v-if="props.remittance.payments.length">
                            <div
                                v-for="(payment, index) in props.remittance.payments"
                                :key="payment.id"
                                class="flex space-x-3 border-grey-500 py-1"
                                :class="{'border-b': index < props.remittance.payments.length - 1}">
                                <div class="w-1/3">{{ payment.payment_reference }}</div>
                                <div class="w-1/3">${{ payment.payment_amount }}</div>
                                <div class="w-1/3">{{ formatDateLocal(payment.created_at, 'MM/dd/yyyy h:mm a') }}</div>
                            </div>
                        </div>
                        <div v-else>
                            No Payments
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!readonly" class="mt-6 py-3 px-3 border border-grey-500 rounded shadow-lg">
                <div class="border-b border-grey-500 pb-3 font-bold">Apply Payment</div>
                <div>
                    <div class="flex">
                        <div class="w-32">Sub total:</div>
                        <div>${{ props.remittance.remittance_sub_total }}</div>
                    </div>
                    <div class="flex">
                        <div class="w-32">Tax total:</div>
                        <div>${{ props.remittance.tax_total }}</div>
                    </div>
                    <div class="flex">
                        <div class="w-32">Total:</div>
                        <div>${{ props.remittance.remittance_total }}</div>
                    </div>
                    <div class="flex">
                        <div class="w-32">Amount Paid:</div>
                        <div class="text-green-600 font-bold">${{ amountPaid.toFixed(2) }}</div>
                    </div>
                    <div class="flex">
                        <div class="w-32">Remaining:</div>
                        <div class="text-orange-600 font-bold">${{ remaining.toFixed(2) }}</div>
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-3 pt-4">
                    <StyledInput
                        v-model="form.payment_reference"
                        name="payment_reference"
                        :label="$t('label.accounting_reference')"
                        :placeholder="$t('label.accounting_reference')" />
                    <StyledInput
                        v-model="form.payment_amount"
                        name="payment_amount"
                        type="number"
                        :label="$t('label.amount_paid')"
                        :placeholder="$t('label.amount_paid')" />
                </div>
                <StyledSaveButton modal :title="'Apply Payment'" @save="save" />
            </div>
        </div>
    </form>
    <template #footer />
</ModalWrapper>
</template>
