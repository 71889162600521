import { useToast } from 'vue-toastification';

export const handleError = (error) => {
    const toast = useToast();
    if (error?.response?.status === 422) {
        toast.error('Please fill in all required fields');
    } else {
        toast.error('An unexpected error occurred');
    }
};
