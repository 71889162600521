/**
 * Creates the PropType validation object, if no default is defined
 * or the default is explicitly set as undefined, then the field is required
 * a third optional validator function can be passed also. This can be a little awkward
 * in the event of a required field needing validation since the correct syntax could be
 *      propType(String, undefined, str => str.length > 10)
 * but that is generally a rare circumstance and you could simple pass a
 * failing default value instead
 *      propType(Number, 0, num => num > 10)
 *
 * @param {Constructor} DataType
 * @param {Any?} Default
 * @param {Function?} Validator
 *
 * @return {Object}
 */
export const propType = (DataType, Default, Validator) => ({
    type: DataType,
    ...(typeof Default !== 'undefined') && { default: Default },
    ...(typeof Default === 'undefined') && { required: true },
    ...(typeof Validator !== 'undefined') && { validator: Validator }
});

/**
 * Strips an object of all functions, breaking vue data binding.
 *
 * @param {Object} obj
 *
 * @return {Object}
 */
export const freeze = obj => JSON.parse(JSON.stringify(obj));

/**
 * Normalizes an object by stripping all null values
 *
 * @param {Object} obj
 *
 * @return {Object}
 */
export const normalize = obj => JSON.parse(JSON.stringify(obj, (key, value) => (value === null ? '' : value)));

/**
 * Utility Loader functions to reduce to load files & negotiate appropriate reducers
 * modalsLoader auto-loads @pderas/VueModality style modals
 * vuexLoader auto-loads vuex modules, and applies namespaces if they have been forgotten
 * routesLoader auto-loads vue-router route definitions
 *
 * @param {Object} modules webpackContext
 *
 * @return {Object}
 */
export const modalsLoader = modules => fileLoader(modules, modalsReducer);
export const vuexLoader = modules => fileLoader(modules, vuexReducer);
export const routesLoader = modules => fileLoader(modules, routesReducer);

/**
 * When passed an array of loaded modules, it extracts the keys, and passes the result
 * to the supplied reducer
 *
 * usage:
 * modules: fileLoader(require.context('./modules', false, /\.js$/i), customReducer)
 *
 * @param {Object} files webpackContext
 * @param {Array} reducer reducer function to format the modules
 */
const fileLoader = (files, reducer) => files.keys().reduce(...reducer(files));

/**
 * Reducer function that converts a require context array to a simple object
 * for use in vuex.
 *
 * Reducer function is wrapped in an array to supply the appropriate default
 * value along side.
 *
 * @param {Function} context
 *
 * @return {Array}
 */
const vuexReducer = context => [
    // array to object reducer
    (acc, cur) => ({
        ...acc,
        [cur
            .split('/')
            .pop()
            .split('.')[0]]: {
            namespaced: true,
            ...context(cur).default
        }
    }),

    // default reducer starting value
    {}
];

/**
 * Reduces loaded files into a flat array of objects.
 * Used with to autoload routes for Vue-Router
 *
 * @param {Object} context webpackContext
 *
 * @return {Array}
 */
const routesReducer = context => [
    (acc, cur) => ([ ...acc, ...context(cur).default ]), []
];

/**
 * Reduces loaded files into a flat array of objects.
 * Used with to autoload routes for Vue-Router
 *
 * @param {Object} context webpackContext
 *
 * @return {Array}
 */
const modalsReducer = context => [
    // array to object reducer
    (acc, cur) => ({
        ...acc,
        [cur
            .split('/')
            .pop()
            .split('.')[0]]: context(cur).default
    }),

    // default reducer starting value
    {}
];
