<script setup>
/* eslint-disable vue/attributes-order */
import { format as dateFormatter } from 'date-fns';
import { computed, watch, useAttrs, ref, inject, onMounted, defineProps, defineEmits, nextTick } from 'vue';

// Define props and emits
const props = defineProps({
    modelValue: {
        type: [String, Array, Date],
        default: null
    },
    errorMessage: {
        type: String,
        default: ''
    },
    placeholder: {
        type: String,
        default: 'Select date'
    },
    format: {
        type: String,
        default: 'MM/dd/yyyy'
    },
    range: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    datePickerColor: {
        type: String,
        default: 'primary'
    },
    showLoading: {
        type: Boolean,
        default: true
    },
    showAdjacentMonths: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update:modelValue', 'input']);

const attrs = useAttrs();
const filteredAttrs = ref(Object.fromEntries(Object.entries(attrs).filter(([key]) => !key.includes('modelValue'))));

const selectedDate = ref(props.modelValue);
const isActive = ref(false);
const lockWatcher = ref(false);

// // Computed property for formatted date
const formattedSelectedDate = computed(() => {
    if (props.range) {
        if (!selectedDate?.value || selectedDate.value?.length < 2) {
            return '';
        }
        const order = [...selectedDate.value].sort((a, b) => new Date(a) - new Date(b));
        return order.map(date => dateFormatter(new Date(date), props.format)).join(' - ');
    } else {
        let date = selectedDate?.value ?? '';
        if (typeof date === 'string' && !date.includes('T')){
            date += 'T00:00:00.000';
        }
        return selectedDate?.value ? dateFormatter(new Date(date), props.format) : '';
    }
});

const errorStore = inject('errorStore');
const getErrors = computed(() => {
    if (props.errorMessage) {
        return [props.errorMessage];
    }
    return errorStore?.all[attrs.name] || [];
});
const isLoading = inject('isLoading');

// Function to clear selected date
const clear = () => {
    selectedDate.value = null;
    emit('update:modelValue', null);
};

// Watch for changes in modelValue prop
watch(() => props.modelValue, (newValue) => {
    if (newValue !== selectedDate.value) {
        selectedDate.value = newValue;
    }
});

// Watch for changes in selectedDate and emit events
watch(selectedDate, (newDate) => {
    if (lockWatcher.value) {
        return;
    }
    if (props.range) {
        if (newDate?.length === 3) {
            // lock the watcher to prevent unending loop
            lockWatcher.value = true;
            selectedDate.value.splice(0, 2);
        }
        if (newDate?.length === 2) {
            const order = [...newDate].sort((a, b) => new Date(a) - new Date(b));
            // lock the watcher to prevent unending loop
            lockWatcher.value = true;
            emit('update:modelValue', order);
            emit('input');
            isActive.value = false;
        }
        // release the lock after the next tick
        nextTick(() => {
            lockWatcher.value = false;
        });
    } else {
        emit('update:modelValue', newDate);
        emit('input');
        isActive.value = false;
    }
});

// Function to set today's date
const setToday = () => {
    const today = new Date();
    selectedDate.value = today;
    emit('update:modelValue', today);
};

// Initialize selectedDate on mount
onMounted(() => {
    if (attrs.modelValue) {
        selectedDate.value = attrs.modelValue;
    }
});
</script>

<template>
<div>
    <!-- eslint-disable-next-line vue/attributes-order -->
    <v-text-field
        v-bind="{...filteredAttrs}"
        v-model="formattedSelectedDate"
        class="styled-inputs styled-input__datepicker"
        :class="{'styled-input__datepicker-loading': isLoading}"
        :disabled="disabled || (isLoading && showLoading)"
        :loading="isLoading && showLoading"
        :error-messages="getErrors"
        :placeholder="placeholder"
        @click:clear="clear"
        @click="isActive = true" />

    <v-dialog v-model="isActive" width="auto">
        <v-date-picker
            v-model="selectedDate"
            v-bind="{...filteredAttrs}"
            :multiple="range"
            :color="datePickerColor"
            :show-adjacent-months="showAdjacentMonths" />
        <StyledButton v-if="!range" @click="setToday">
            Today
        </StyledButton>
    </v-dialog>
</div>
</template>
