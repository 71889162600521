import { ref } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';

export const useDealershipStore = defineStore('dealership', () => {
    const all = ref({});

    const reset = () => {
        all.value = {};
    };

    const actions = {
        searchDealerships: (params) => axios.get('/api/v1/dealerships', { params }),
        storeDealership: (params) => axios.post('/api/v1/dealerships', params),
        updateDealership: (params) => axios.patch('/api/v1/dealerships/' + params.hash, params),
        destroyDealership: (params) => axios.delete('/api/v1/dealerships/' + params.hash)
    };

    return {
        all,
        reset,
        ...actions,
    };
});
