// To be reused in components/stores to check if the user has a specific role
const hasRole = (profile, roleName) => profile?.roles?.some(role => role.name === roleName);

export const isRole = (profile) => ({
    superAdmin: () => hasRole(profile, 'super_admin'),
    admin: () => hasRole(profile, 'admin'),
    dealershipManager: () => hasRole(profile, 'dealership_manager'),
    finance: () => hasRole(profile, 'finance'),
    fsm: () => hasRole(profile, 'fsm')
});
