<script setup>
import { ref, watch, computed } from 'vue';
import { useCertificateStore } from '@/stores/certificate';
import { storeToRefs } from 'pinia';
import { handleProductRules, isIneligible, isProductFieldSelectable } from '@/utils/productRulesHandler';
import { handleError } from '@/mixins/ErrorHandlingMixin';

const props = defineProps({
    product: {
        type: Object,
        required: true
    },

    readOnly: {
        type: Boolean,
        default: false
    },

    ineligibleReason: {
        type: String,
        default: ''
    }
});

const product = props.product;
const sections = product.product_fields;
const { active } = storeToRefs(useCertificateStore());
const certificateStore = useCertificateStore();
const { saveProduct } = certificateStore;
const enabledSection = sections.find(s => s.name === 'Enabled');
const enabledOption = enabledSection.product_field_options.find(o => o.name === 'Enabled');
const sectionsWithoutEnabled = sections.filter(s => s.name !== 'Enabled');

const liveUpdateTimeout = ref(null);
const form = ref({});
const initForm = () => {
    const existingProduct = active.value.certificate_products.find(p => p.product_id === product.id);
    form.value = existingProduct ? JSON.parse(JSON.stringify(existingProduct)) : {
        hash: null,
        name: product.name,
        fee_total: 0.0,
        gst_total: 0.0,
        pst_total: 0.0,
        certificate_product_fields: []
    };
    form.value.product = JSON.parse(JSON.stringify(product));
};
initForm();

const gst = active.value.province.taxes.find(tax => ['GST', 'HST'].includes(tax?.tax_type?.name));
const gstRate = gst ? gst.tax_rate / 100 : 0;
const pst = active.value.province.taxes.find(tax => tax?.tax_type?.name === 'PST');
const pstRate = pst ? pst.tax_rate / 100 : 0;

const lockTaxesSection = ref(true);
// If the taxes calculated are not equal to the taxes entered by the user, unlock the taxes section
if (
    (Number(form.value.fee_total) * gstRate).toFixed(2) != Number(form.value.gst_total).toFixed(2) ||
    (Number(form.value.fee_total) * pstRate).toFixed(2) != Number(form.value.pst_total).toFixed(2)
) {
    lockTaxesSection.value = false;
}

const totalFee = computed(() => {
    const total = isTaxExempt.value
        ? Number(form.value.fee_total)
        : Number(form.value.fee_total) + Number(form.value.gst_total) + Number(form.value.pst_total);
    return total.toFixed(2);
});

const isEnabled = computed(() => {
    return form.value.certificate_product_fields.find(field => field.product_field_option_name === 'Enabled');
});

const isTaxExempt = computed(() => {
    return !!active.value.buyers.find(buyer => buyer.treaty_number);
});

const onInputField = (value, section, option = null) => {
    const productFieldName = section.name;
    if (section.type === 'dropdown') {
        option = section.product_field_options.find(o => o.id === value);
    }
    const productFieldOptionName = option?.name ?? null;
    const productFieldValue = value;
    const index = form.value
        .certificate_product_fields
        .findIndex(field => {
            if (option && section.type !== 'dropdown') {
                return field.product_field_name === productFieldName && field.product_field_option_name === productFieldOptionName;
            } else {
                return field.product_field_name === productFieldName;
            }
        });
    if (value) {
        if (index !== -1) {
            form.value.certificate_product_fields[index].product_field_option_name = productFieldOptionName;
            form.value.certificate_product_fields[index].product_field_value = productFieldValue;
        } else {
            form.value.certificate_product_fields.push({
                product_field_name: productFieldName,
                product_field_option_name: productFieldOptionName,
                product_field_value: productFieldValue,
                product_field_id: section.id,
                product_id: product.id
            });
        }
    } else {
        if (index !== -1) {
            form.value.certificate_product_fields.splice(index, 1);
        }
    }

    handleProductRules(value, form.value, product, section, option);

    clearTimeout(liveUpdateTimeout.value);
    liveUpdateTimeout.value = setTimeout(() => {
        save();
    }, 500);
};

const onToggleProduct = (value, section, option) => {
    onInputField(value, section, option);
    if (!value) {
        form.value.certificate_product_fields = form.value.certificate_product_fields.filter(field => field.product_id !== product.id);
        form.value.fee_total = 0.0;
        form.value.gst_total = 0.0;
        form.value.pst_total = 0.0;
    }
};

const getFieldValue = (section, option = null) => {
    const index = form.value
        .certificate_product_fields
        .findIndex(field => {
            if (option == null) {
                return field.product_field_name === section.name;
            } else {
                return field.product_field_name === section.name && field.product_field_option_name === option.name;
            }
        });
    const certificateProductField = form.value.certificate_product_fields[index];
    if (!certificateProductField) {
        return null;
    }
    if (section.type === 'checkbox') {
        return Boolean(certificateProductField.product_field_value);
    } else if (section.type === 'dropdown') {
        return certificateProductField.product_field_option_name;
    } else {
        return certificateProductField.product_field_value;
    }
};

const getFieldDropdownOptions = (product, section, options) => {
    if (!active.value.bundle && section.name === 'Term') {
        return options.filter(option => option.name !== '1 Year');
    }

    if (active.value.bundle && section.name == 'Tire Size') {
        return options.filter(option => Number(option.name) <= 20);
    }

    return options;
};

const calculateTaxes = () => {
    if (lockTaxesSection.value) {
        form.value.gst_total = !isTaxExempt.value ? (Number(form.value.fee_total) * Number(gstRate)).toFixed(2) : 0;
        form.value.pst_total = !isTaxExempt.value ? (Number(form.value.fee_total) * Number(pstRate)).toFixed(2) : 0;
    }

    clearTimeout(liveUpdateTimeout.value);
    liveUpdateTimeout.value = setTimeout(() => {
        save();
    }, 500);
};

const editTaxes = () => {
    lockTaxesSection.value = false;
};

const clearTaxes = () => {
    lockTaxesSection.value = true;
    calculateTaxes();
};

const onInputTaxes = () => {
    save();
};

const save = async () => {
    try {
        await saveProduct({
            ...form.value,
            hash: active.value.hash,
        });
    } catch (error) {
        handleError(error);
    }
};

watch(() => active.value, () => {
    initForm();
});
</script>

<template>
<div class="space-y-3">
    <div class="flex space-x-3">
        <h6 class="mt-0.5">{{ product.name }}</h6>
        <StyledRoundSwitch
            v-if="!ineligibleReason"
            key="option_enabled"
            :model-value="getFieldValue(enabledSection, enabledOption)"
            :disabled="isIneligible(active, form, product, enabledSection, enabledOption) || props.readOnly || !isProductFieldSelectable(active, enabledSection, product)"
            inset
            :name="enabledOption.name"
            class="-mt-1"
            @update:modelValue="value => onToggleProduct(value, enabledSection, enabledOption)" />
    </div>

    <template v-if="!ineligibleReason">
        <template v-if="isEnabled">
            <div class="grid lg:grid-cols-2 grid-cols-1 gap-3">
                <div v-for="section in sectionsWithoutEnabled" :key="`section_${section.id}`" class="border border-grey-500 shadow rounded p-3">
                    <div class="font-bold border-b border-grey-500 mb-3 pb-2">{{ section.name }}</div>
                    <div v-if="section.type === 'input'">
                        <StyledInput
                            :model-value="getFieldValue(section)"
                            :label="section.name"
                            :name="section.name"
                            :disabled="props.readOnly || !isProductFieldSelectable(active, section, product)"
                            type="number"
                            @update:modelValue="value => onInputField(value, section)" />
                    </div>
                    <div v-else-if="section.type === 'dropdown'">
                        <StyledDropdown
                            :model-value="getFieldValue(section)"
                            :options="getFieldDropdownOptions(product, section, section.product_field_options)"
                            item-title="name"
                            item-value="id"
                            :label="section.name"
                            :name="section.name"
                            :clearable="section.name != 'Term'"
                            :disabled="props.readOnly || !isProductFieldSelectable(active, section, product) || ['Number of Keys', 'Number of Remotes'].includes(section.name)"
                            @update:modelValue="value => onInputField(value, section)" />
                    </div>
                    <div v-else-if="section.type === 'checkbox'" class="grid grid-cols-2 gap-3">
                        <StyledRoundSwitch
                            v-for="option in section.product_field_options"
                            :key="`option_${option.id}`"
                            :model-value="getFieldValue(section, option)"
                            :label="option.name"
                            :disabled="isIneligible(active, form, product, section, option) || props.readOnly || !isProductFieldSelectable(active, section, product)"
                            inset
                            :name="option.name"
                            @update:modelValue="value => onInputField(value, section, option)" />
                    </div>
                    <div v-else-if="section.type === 'radio'">
                        <!-- TODO: -->
                    </div>
                </div>
            </div>

            <div v-if="!active.bundle || ['Antitheft', 'Protection'].includes(product.name)" class="border border-grey-500 shadow rounded p-3">
                <div class="font-bold border-b border-grey-500 mb-3 pb-2">Fee</div>
                <div class="flex items-end space-x-3">
                    <div class="w-4/5">
                        <StyledInput
                            v-model="form.fee_total"
                            label="Fee"
                            name="fee"
                            :disabled="props.readOnly"
                            @update:modelValue="calculateTaxes" />
                        <div v-if="!isTaxExempt" class="border border-grey-500 rounded shadow-lg p-3">
                            <div class="font-bold border-b border-grey-500 mb-3 pb-2 flex justify-between">
                                <div>Taxes</div>
                                <div v-if="!props.readOnly" class="flex space-x-6">
                                    <div
                                        v-if="lockTaxesSection"
                                        class="underline hover:cursor-pointer text-blue-500 hover:text-blue-700"
                                        @click="editTaxes">
                                        Edit Taxes
                                    </div>
                                    <div
                                        v-else
                                        class="underline hover:cursor-pointer text-orange-700 hover:text-orange-900"
                                        @click="clearTaxes">
                                        Reset Tax Calculation
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-2 gap-3">
                                <StyledInput
                                    v-model="form.gst_total"
                                    :disabled="lockTaxesSection || props.readOnly"
                                    label="GST/HST"
                                    name="gst"
                                    @blur="onInputTaxes" />
                                <StyledInput
                                    v-model="form.pst_total"
                                    :disabled="lockTaxesSection || props.readOnly"
                                    label="PST"
                                    name="pst"
                                    @blur="onInputTaxes" />
                            </div>

                        </div>
                    </div>
                    <div class="border border-grey-500 shadow-lg rounded p-3 w-1/5 h-fit bg-cyan-100">
                        <div class="font-bold border-b border-grey-500 mb-3 pb-2">Total Fee</div>
                        <div class="text-lg font-bold">
                            ${{ totalFee }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </template>
    <div v-else class="font-bold text-red-500 text-lg">
        {{ ineligibleReason }}
    </div>
</div>
</template>
