const formatAmount = (amount, formatType = 'en-CA', decimal = 2) => {
    return new Intl.NumberFormat(formatType, {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: decimal
    })
        .format(amount);
};

export { formatAmount };
