export default {
    methods: {
        downloadFromAxiosResponse(response, defaultFilename = 'report.xlsx') {
            const blob = new Blob([response.data], {type: response.data.type});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            const filename = response.headers['content-disposition']?.match(/filename="?(.+)\.([a-zA-Z]+)"?/)?.[1] + '.' + response.headers['content-disposition']?.match(/filename="?(.+)\.([a-zA-Z]+)"?/)?.[2] || defaultFilename;

            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);

            link.click();
        }
    }
};
