const users = {
    name: 'Users/UsersPage',
    path: '/users',
    icon: 'mdi-account-group',
    label: 'Users'
};

const dealerships = {
    name: 'Dealerships/DealershipsPage',
    path: '/dealerships',
    icon: 'mdi-car-multiple',
    label: 'Dealerships'
};

const profile = {
    name: 'Dashboard/UserProfilePage',
    path: '/profile',
    icon: 'mdi-account',
    label: 'Profile'
};

const horizon = {
    name: 'Horizon',
    path: '/horizon',
    icon: 'mdi-sun-angle',
    label: 'Horizon',
    private: true,
    privateLabel: 'SA'
};

const pulse = {
    name: 'Pulse',
    path: '/pulse',
    icon: 'mdi-pulse',
    label: 'Pulse',
    private: true,
    privateLabel: 'SA'
};

const telescope = {
    name: 'Telescope',
    path: '/telescope',
    icon: 'mdi-telescope',
    label: 'Telescope',
    private: true,
    privateLabel: 'SA'
};

const lenders = {
    name: 'Lenders/LendersPage',
    path: '/lenders',
    icon: 'mdi-cash-multiple',
    label: 'Lenders'
};

const certificates = {
    name: 'Certificates/CertificatesPage',
    path: '/certificates',
    icon: 'mdi-file-multiple',
    label: 'Certificates'
};

const remittances = {
    name: 'Remittances/RemittancesPage',
    path: '/remittances',
    icon: 'mdi-checkbook-arrow-left',
    label: 'Remittances'
};

const claims = {
    name: 'Claims/ClaimsPage',
    path: '/claims',
    icon: 'mdi-file-plus-outline',
    label: 'Claims'
};

export const superAdmin = _ => {
    return [
        users,
        dealerships,
        lenders,
        certificates,
        remittances,
        claims
    ];
};

export const admin = _ => {
    return [
        users,
        dealerships,
        lenders,
        certificates,
        remittances,
        claims
    ];
};

export const dealershipManager = _ => {
    return [
        users,
        certificates,
        remittances,
        claims
    ];
};

export const finance = _ => {
    return [
        lenders,
        certificates
    ];
};

export const fsm = _ => {
    return [
        certificates,
        claims
    ];
};

export const bottomNavRoutes = _ => {
    return [
        profile,
        horizon,
        pulse,
        telescope,
    ];
};
