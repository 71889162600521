<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import StyledVDatepicker from '@/components/StyledVDatepicker';
import { format } from 'date-fns';
import { ref } from 'vue';

const selectedDateRange = ref([]);

const emit = defineEmits(['close-modal']);

const confirmModal = () => {
    const dates = selectedDateRange.value.map(date => format(date, 'yyyy-MM-dd'));
    emit('close-modal', dates);
};
</script>

<template>
<ModalWrapper body-class="modal-default-content" :fixed-height="false">
    <template #header>Download Master Export</template>
    <template #subheader>Please select the date range</template>

    <div>
        <StyledVDatepicker
            v-model="selectedDateRange"
            :range="true"
            placeholder="Select date range" />
    </div>

    <template #footer>
        <StyledButton :disabled="selectedDateRange.length === 0" @click="confirmModal">Download</StyledButton>
    </template>
</ModalWrapper>
</template>

