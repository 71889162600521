<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { router } from '@inertiajs/vue3';
import { useCertificateStore } from '@/stores/certificate';
import { storeToRefs } from 'pinia';

const { active } = storeToRefs(useCertificateStore());
const certificateStore = useCertificateStore();
const { voidCertificate } = certificateStore;

const emit = defineEmits(['close-modal']);

const voidAndReopen = async () => {
    const response = await voidCertificate({
        hash: active.value.hash,
        status: 'in_progress',
    });

    closeModal();

    if (response.data.certificate) {
        router.visit('/certificates/' + response.data.certificate);
    }
};
const voidAndCancel = async () => {
    await voidCertificate({
        hash: active.value.hash,
        status: 'cancelled',
    });
    
    closeModal();

    router.visit('/certificates');
};
const closeModal = () => {
    emit('close-modal', true);
};
</script>

<template>
<ModalWrapper body-class="modal-default-content" :fixed-height="false">
    <template #header>{{ $t('title.confirm_certificate_void_action') }}</template>
    <template #subheader>{{ $t('title.confirm_certificate_void_message') }}</template>

    <template #footer>
        <StyledButton @click="voidAndReopen">{{ $t('label.void_and_reopen') }}</StyledButton>
        <StyledButton @click="voidAndCancel">{{ $t('label.void_and_cancel') }}</StyledButton>
    </template>
</ModalWrapper>
</template>

