
<script setup>
import { ref } from 'vue';

const drawer = ref(true);

</script>

<template>
<div>
    <div class="fixed transform -translate-y-1/2 right-0 top-1/2">
        <v-app-bar-nav-icon
            v-tooltip.right="'Certificate Totals'"
            class="bg-primary hover:bg-primary-light rounded-r-lg"
            icon="mdi-arrow-collapse-left"
            @click="drawer = true" />
    </div>
  
    <!-- The slide over panel -->
    <v-navigation-drawer
        v-model="drawer"
        location="right">
        <div class="px-3 py-2">
            <div class="hidden md:flex justify-start">
                <v-app-bar-nav-icon
                    v-tooltip.right="'Minimize'"
                    icon="mdi-arrow-collapse-right"
                    @click="drawer = false" />
            </div>
            <slot />
        </div>
    </v-navigation-drawer>
</div>
</template>

