import { defineStore } from 'pinia';

export const useOptionsStore = defineStore('options', {
    state: () => ({
        roles: [],
        statuses: [],
        remittanceStatuses: [],
        dealerships: [],
        certificates: [],
        provinces: [],
        taxes: [],
        genders: [],
        titles: [],
        lenders: [],
        dealTypes: [],
        vehicleTypes: [],
        preferred_contact_methods: [],
        phone_types: [],
        twoFactorMethods: [],
        securityQuestionTemplates: [],
        securityCheckpoint: false,
        products: []
    }),
    actions: {
        getDealershipProvinceTaxes(dealership) {
            return this.taxes.filter(tax => tax.province_id === dealership.province_id);
        },
        reset() {
            this.$reset();
        },
    }
});

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(useOptionsStore.acceptHMRUpdate);
}
