<script setup>
import { inject, onMounted } from 'vue';
import LoginContainer from '@/components/Auth/LoginContainer';
import axios from 'axios';
import { router } from '@inertiajs/vue3';
import { useToast } from 'vue-toastification';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

const toast = useToast();
const { locale } = storeToRefs(useUserStore());

const props = defineProps({
    errors: {
        type: Object,
        default: _ => ({})
    }
});

const user = {
    email: '',
    password: '',
    code: ''
};

const getError = selector => (props.errors[selector] || null);

onMounted(() => {
    const urlParams = new URLSearchParams(window.location.search);
    user.code = urlParams.get('code');
});

const isLoading = inject('isLoading');
const login = async () => {
    if (!isLoading.value) {
        await axios.get('/sanctum/csrf-cookie');
        const payload = {
            ...user,
            locale: locale.value
        };
        axios.post('/login', payload)
            .then(({ data }) => {
                router.visit(data?.redirect || '/certificates');
            }).catch(() => {
                console.error('Login failed');
            });
    }
};

const navigateToForgotPassword = () => {
    router.visit('/forgot-password');
};

const disablePaste = (event) => {
    event.preventDefault();
    toast.clear();
    toast.error('Pasting is not allowed');
};
</script>
<template>
<LoginContainer>
    <template #title>{{ $t('auth.login.sign_in') }}</template>
    <form @submit.prevent="login">
        <StyledInput
            v-model="user.email"
            type="email"
            name="email"
            :error-message="getError('email')"
            :placeholder="$t('label.email')"
            :label="$t('label.email')" />
        <StyledPassword
            v-model="user.password"
            class="password"
            :error-message="getError('password')"
            name="password"
            placeholder="******************"
            :label="$t('label.password')"
            @paste="disablePaste" />
        <StyledButton
            type="submit"
            color="primary"
            class="mb-4">
            {{ $t('auth.login.sign_in') }}
        </StyledButton>
    </form>
    <div class="flex justify-between items-center">
        <StyledButton
            variant="outlined"
            @click="$inertia.visit('/register')">
            {{ $t('auth.login.sign_up') }}
        </StyledButton>
        <a
            href="#"
            class="router-link"
            @click="navigateToForgotPassword">{{ $t('auth.login.forgot_password') }}</a>
    </div>
</LoginContainer>
</template>

