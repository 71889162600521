<script setup type="module">
import { ref, inject } from 'vue';
import { useDealershipStore } from '@/stores/dealership';
import { storeToRefs } from 'pinia';

const params = ref({
    term: '',
    status: 'All',
    page: 1,
    sort_by: '',
    per_page: 10
});

const dealershipStore = useDealershipStore();
const { searchDealerships, destroyDealership } = dealershipStore;
const { all } = storeToRefs(dealershipStore);

const clearSearch = () => {
    params.value.term = '';
    search();
};

const tableUpdate = ({page, sortBy, itemsPerPage}) => {
    params.value.page = page;
    params.value.sort_by = sortBy;
    params.value.per_page = itemsPerPage;
    search();
};

const search = () => {
    searchDealerships(params.value);
};

const deleteDealership = async (dealership) => {
    const confirm = await modals.show('ConfirmationModal', {
        props: {
            title: 'Confirm Dealership Removal',
            prompt: 'Delete ' + dealership.name + '?'
        }
    });

    if (confirm) {
        destroyDealership({
            hash: dealership.hash
        }).then(_ => {
            search();
        });
    }
};

const modals = inject('modals');
const openModal = (modal, mode = 'Create', dealership = null) => {
    if (mode == 'Edit') {
        modals.show(modal, {
            props: {
                dealership,
                mode: 'Edit'
            }
        }).then(_ => {
            search();
        });
    } else {
        modals.show(modal).then(_ => {
            search();
        });
    }
};

</script>
<template>
<MainContentLayout>
    <template #breadcrumb>
        <BreadCrumb />
    </template>
    <template #title>
        Dealerships
    </template>
    <template #actions>
        <StyledButton @click="openModal('DealershipsModal')">
            <FontAwesomeIcon
                icon="plus"
                class="mr-1" />
            Add Dealership
        </StyledButton>
    </template>
    <div class="grid md:grid-cols-3 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="search"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDropdown
            v-model="params.status"
            name="status"
            :options="['All', 'Active', 'Inactive']"
            label="Status"
            @input="search" />
    </div>

    <DataTable
        v-if="all"
        :data="all"
        :headers="[
            { title: $t('label.dealership_name'), key: 'name', value: item => `${item.name}` },
            { title: $t('label.dealership_address'), key: 'address'},
            { title: $t('label.province'), key: 'province.name'},
            { title: $t('label.city'), key: 'city'},
            { title: $t('label.active'), key: 'is_active' },
        ]"
        :actions="[
            { title: 'Edit', icon: 'user-edit', action: (item) => openModal('DealershipsModal', 'Edit', item) },
            { title: 'Delete', icon: 'user-times', action: (item) => deleteDealership(item) },
        ]"
        @table-update="tableUpdate">
        <template #[`item.is_active`]="{ item }">
            <vChip :color="item.is_active ? 'green' : 'red'">{{ item.is_active ? 'Yes' : 'No' }}</vChip>
        </template>
    </DataTable>

</MainContentLayout>
</template>

