import { ref } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';

export const useRemittanceStore = defineStore('remittance', () => {
    const all = ref({});

    const reset = () => {
        all.value = {};
    };

    const actions = {
        searchRemittances: (params) => axios.get('/api/v1/remittances', { params }),
        storeRemittance: (params) => axios.post('/api/v1/remittances', params),
        applyPayment: (params) => axios.post(`/api/v1/remittances/${params.hash}/apply-payment`, params),
        updateRemittance: (params) => axios.patch('/api/v1/remittances/' + params.hash, params),
        destroyRemittance: (params) => axios.delete('/api/v1/remittances/' + params.hash),
        downloadRemittance: (params) => axios.get('/api/v1/remittances/' + params.hash + '/download', { responseType: 'blob' }),
    };

    return {
        all,
        reset,
        ...actions,
    };
});
