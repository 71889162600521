<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useDealershipStore } from '@/stores/dealership';
import { useOptionsStore } from '@/stores/options';
import { handleError } from '@/mixins/ErrorHandlingMixin';

const props = defineProps({
    dealership: {
        type: Object,
        default: () => ({
            province: null,
            name: '',
            dealership_gst_number: '',
            phone_number: '',
            address: '',
            address2: '',
            city: '',
            postal: '',
            is_active: true
        })
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const emit = defineEmits(['close-modal']);
const form = ref({ ...props.dealership });

const toast = useToast();

const { provinces } = useOptionsStore();

const dealershipStore = useDealershipStore();
const { storeDealership, updateDealership } = dealershipStore;

const save = async () => {
    try {
        const action = props.mode === 'Create' ? storeDealership : updateDealership;
        const toastMessage = props.mode === 'Create' ? 'Dealership Created' : 'Dealership Updated';

        await action({
            ...form.value,
            hash: props.dealership.hash,
        });

        toast.success(toastMessage);
        emit('close-modal');
    } catch (error) {
        handleError(error);
    }
};
</script>
<template>
<ModalWrapper>
    <template #header>{{ mode }} dealership</template>
    <template #subheader>Create or edit a dealership</template>

    <form @submit.prevent="save">
        <div class="grid md:grid-cols-2 gap-x-4 gap-y-3">
            <StyledInput
                v-model="form.name"
                name="name"
                :placeholder="$t('label.name')"
                :label="$t('label.name')" />
            <StyledInput
                v-model="form.dealership_gst_number"
                name="dealership_gst_number"
                :placeholder="$t('label.dealership_gst_number')"
                :label="$t('label.dealership_gst_number')" />
            <StyledMask
                v-model="form.phone_number"
                :mask="['###-###-####']"
                :label="$t('label.phone_number')"
                name="phone_number"
                placeholder="###-###-####" />
            <StyledDropdown
                v-model="form.province_id"
                item-title="name"
                item-value="id"
                :options="provinces"
                name="province_id"
                :placeholder="$t('label.please_select')"
                :label="$t('label.province')" />
            <StyledInput
                v-model="form.address"
                name="address"
                :placeholder="$t('label.address')"
                :label="$t('label.address')" />
            <StyledInput
                v-model="form.address2"
                name="address2"
                :placeholder="$t('label.address_line_2')"
                :label="$t('label.address_line_2')" />
            <StyledInput
                v-model="form.city"
                name="city"
                :placeholder="$t('label.city')"
                :label="$t('label.city')" />
            <StyledMask
                v-model="form.postal"
                name="postal"
                placeholder="A1A 1A1"
                :options="{
                    mask: '@#@ #@#',
                    tokens: {
                        '#': { pattern: /\d/ },
                        '@': { pattern: /[a-zA-Z]/, transform: v => v.toUpperCase()}
                    }
                }"
                :label="$t('label.postal')" />
            <StyledRoundSwitch
                v-model="form.is_active"
                label="Is Active"
                inset
                name="is_active" />
        </div>
    </form>

    <template #footer>
        <StyledSaveButton modal @save="save" />
    </template>
</ModalWrapper>
</template>
