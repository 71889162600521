<script setup>
import { computed } from 'vue';
import { router } from '@inertiajs/vue3';
import { useStringFormatter } from '@/composables/stringFormatter';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

const props = defineProps({
    models: {
        type: Array,
        default: () => []
    },
    showHome: {
        type: Boolean,
        default: true
    },
    customCrumbs: {
        type: Array,
        default: null
    }
});
const { capitalizeFirstLetter } = useStringFormatter();

const { profile } = storeToRefs(useUserStore());

const splitUrl = router.page.url.split('/').filter(x => x !== '');
const breadCrumbs = computed(() => {
    let href= '';

    let crumbs;
    if (props.customCrumbs != null) {
        crumbs = props.customCrumbs;
    } else {
        crumbs = splitUrl.map((item) => {
            href += '/' + item;
            const title = props.models.find(model => item === model.hash)?.title ?? capitalizeFirstLetter(item);

            return {
                title: title.split('?')[0].replace(/-/g, ' '), //remove any query params or dashes
                disabled: false,
                href: href
            };
        });
    }

    if (crumbs.length > 0 && props.showHome) {
        crumbs.unshift();
    }

    //Disable the last breadcrumb
    if (crumbs.length > 0) {
        crumbs[crumbs.length - 1].disabled = true;
    }

    return crumbs;
});
</script>
<template>
<div v-if="profile">
    <v-breadcrumbs density="compact" :items="breadCrumbs">
        <template #title="{ item }">
            <div class="flex items-center gap-2 capitalize">
                <template v-if="item.icon"><v-icon v-tooltip="item.type" class="hover:no-underline" :icon="item.icon" /></template> {{ item.title }}
            </div>
        </template>
    </v-breadcrumbs>
</div>
</template>
<style scoped>
.v-breadcrumbs {
    padding-left: 0;
}
.v-breadcrumbs-item {
    padding-left: 0;
}
</style>
