<script setup type="module">
import { ref, inject } from 'vue';
import { useLenderStore } from '@/stores/lender';
import { storeToRefs } from 'pinia';

const params = ref({
    term: '',
    role: null,
    status: null
});

const lenderStore = useLenderStore();
const { searchLenders, destroyLender, getLenderTypeString } = lenderStore;
const { all } = storeToRefs(lenderStore);
const statuses = [
    { display_name: 'Active', value: true },
    { display_name: 'Inactive', value: false }
];

const clearSearch = () => {
    params.value.term = '';
    search();
};

const tableUpdate = ({page, sortBy, itemsPerPage}) => {
    params.value.page = page;
    params.value.sort_by = sortBy;
    params.value.per_page = itemsPerPage;
    search();
};

const search = () => {
    searchLenders(params.value);
};

const deleteLender = async (lender) => {
    const confirm = await modals.show('ConfirmationModal', {
        props: {
            title: 'Confirm Lender Removal',
            prompt: 'Delete ' + lender.name + '?'
        }
    });

    if (confirm) {
        destroyLender({
            hash: lender.hash
        }).then(_ => {
            search();
        });
    }
};

const modals = inject('modals');
const openModal = (modal, mode = 'Create', lender = null) => {
    if (mode == 'Edit') {
        modals.show(modal, {
            props: {
                lender: {
                    ...lender,
                },
                mode: 'Edit'
            }
        }).then(_ => {
            search();
        });
    } else {
        modals.show(modal).then(_ => {
            search();
        });
    }
};

</script>
<template>
<MainContentLayout>
    <template #breadcrumb>
        <BreadCrumb />
    </template>
    <template #title>
        {{ $t('label.lenders') }}
    </template>
    <template #actions>
        <StyledButton @click="openModal('LendersModal')">
            <FontAwesomeIcon
                icon="plus"
                class="mr-1" />
            {{ $t('label.add_lender') }}
        </StyledButton>
    </template>
    <div class="grid md:grid-cols-3 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="search"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDropdown
            v-model="params.status"
            name="status"
            item-title="display_name"
            item-value="value"
            :options="statuses"
            :label="$t('label.status')"
            @input="search" />
    </div>

    <DataTable
        v-if="all"
        :data="all"
        :headers="[
            { title: $t('label.name'), key: 'name' },
            { title: $t('label.contact_number'), key: 'contact_number' },
            { title: $t('label.address'), key: 'address' },
            { title: $t('label.type'), key: 'type', value: item => getLenderTypeString(item) },
            { title: $t('label.status'), key: 'is_active' },
        ]"
        :actions="[
            { title: $t('label.edit'), icon: 'lender-edit', action: (item) => openModal('LendersModal', 'Edit', item) },
            { title: $t('label.delete'), icon: 'lender-times', action: (item) => deleteLender(item) },
        ]"
        @table-update="tableUpdate">
        <template #[`item.is_active`]="{ item }">
            <vChip :color="item.is_active ? 'green' : 'red'">{{ item.is_active ? $t('label.yes') : $t('label.no') }}</vChip>
        </template>
    </DataTable>

</MainContentLayout>
</template>

