<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref } from 'vue';
import { useCertificateStore } from '@/stores/certificate';
import { formatAmount } from '@/utils/formatHelper';

const certificateStore = useCertificateStore();
const { getCustomerName, getFsmName } = certificateStore;

const props = defineProps({
    certificates: {
        type: Object,
        default: () => ({
            global_status_id: null,
            remittance_sub_total: 0,
            tax_total: 0,
            remittance_total: 0,
            note: '',
            dealership_id: null,
            certificates: []
        })
    }
});

const all = ref({
    data: props.certificates,
    total: props.certificates.length,
    per_page: 100
});

</script>
<template>
<ModalWrapper>
    <template #header>Refunded Certificates</template>

    <DataTable
        :data="all"
        :headers="[
            { title: $t('label.cert_no'), key: 'certificate_number' },
            { title: $t('label.customer_name'), key: 'customer_name', value: item => getCustomerName(item)  },
            { title: $t('label.remittance_total'), key: 'remittance_total', value: item => formatAmount(item.remittance_total) },
            { title: $t('label.dealership'), key: 'dealership_id', value: item => item.dealership.name },
            { title: $t('label.fsm'), key: 'fsm', value: item => getFsmName(item) },
            { title: $t('label.status'), key: 'global_status_id', value: item => item.global_status.name },
            { title: $t('label.purchase_date'), key: 'purchase_date' },
        ]" />
</ModalWrapper>
</template>
