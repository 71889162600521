import { ref } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';

export const useClaimStore = defineStore('claim', () => {
    const all = ref(null);
    const locale = ref('en');

    const actions = {
        getClaimTypeString(claim) {
            return claim.type ? claim.type.charAt(0).toUpperCase() + claim.type.slice(1) : '';
        },
        searchClaims: (params) => axios.get('/api/v1/claims', { params }),
        storeClaim: (params) => axios.post('/api/v1/claims', params),
        updateClaim: (params) => axios.patch('/api/v1/claims/' + params.hash, params),
        destroyClaim: (params) => axios.delete('/api/v1/claims/' + params.hash),
    };

    return {
        all,
        locale,
        ...actions,
    };
});
