<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { computed, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useClaimStore } from '@/stores/claim';
import { useOptionsStore } from '@/stores/options';
import { useUserStore } from '@/stores/user';
import StyledVDatepicker from '@/components/StyledVDatepicker';
import { storeToRefs } from 'pinia';

const props = defineProps({
    claim: {
        type: Object,
        default: () => ({
            global_status_id: null,
            dealership_id: null,
            certificate_id: null,
            claim_date: null,
            first_name: null,
            last_name: null,
            amount: 0,
            type: null,
            work_order_number: null,
            contact_number: null,
            contact_email: null,
            description: null,
            claimant: null
        })
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const emit = defineEmits(['close-modal']);
const form = ref({ ...props.claim });
form.value.amount = Number(form.value.amount);
const toast = useToast();
const { statuses, dealerships, certificates } = useOptionsStore();
const { profile, userProfile } = storeToRefs(useUserStore());
const types = [
    { name: 'Bank', value: 'bank' },
    { name: 'Private', value: 'private' },
];

const getDealerships = computed(() => {
    if (userProfile.value.hasRole(['dealership_manager', 'fsm'])) {
        return dealerships.filter(dealership => userProfile.value.dealerships.some(userDealership => userDealership.id === dealership.id));
    }
    return dealerships;
});

const certificate = computed(() => {
    return certificates.find(c => c.id === form.value.certificate_id);
});
if (props.mode === 'Create') {
    form.value.contact_number = profile.value.phone_number;
    form.value.contact_email = profile.value.email;
} else {
    form.value.claim_date = new Date(`${form.value.claim_date}T00:00:00.000`);
    if (certificate.value) {
        form.value.claimant = certificate.value.buyers.find(b => b.first_name === form.value.first_name && b.last_name === form.value.last_name);
    }
}

const claimant = computed(() => {
    if (!certificate.value) {
        return null;
    }
    return certificate.value.buyers.find(b => b.id === form.value.claimant);
});

const filteredCertificates = computed(() => {
    return certificates.filter(c => c.dealership_id === form.value.dealership_id);
});

const buyers = computed(() => {
    if (!certificate.value) {
        return [];
    }
    return certificate.value.buyers;
});

const claimStore = useClaimStore();
const { storeClaim, updateClaim } = claimStore;

const save = async () => {
    try {
        const action = props.mode === 'Create' ? storeClaim : updateClaim;
        const toastMessage = props.mode === 'Create' ? 'Claim Created' : 'Claim Updated';

        await action({
            ...form.value,
            hash: props.claim.hash,
        });

        toast.success(toastMessage);
        emit('close-modal');
    } catch (error) {
        toast.error('An unexpected error occurred');
    }
};

const onSelectClaimant = () => {
    if (!claimant.value) {
        form.value.first_name = null;
        form.value.last_name = null;
        return;
    }
    form.value.first_name = claimant.value.first_name;
    form.value.last_name = claimant.value.last_name;

};
</script>
<template>
<ModalWrapper>
    <template #header>{{ mode }} {{ $t('label.claim') }}</template>
    <template #subheader>{{ $t('label.create_or_edit_claim') }}</template>

    <form @submit.prevent="save">
        <div class="grid md:grid-cols-2 gap-x-4 gap-y-3">
            <StyledDropdown
                v-model="form.global_status_id"
                item-title="name"
                item-value="id"
                :options="statuses"
                name="global_status_id"
                :placeholder="$t('label.please_select')"
                :label="$t('label.status')" />
            <StyledDropdown
                v-model="form.dealership_id"
                name="dealership_id"
                item-title="name"
                item-value="id"
                :options="getDealerships"
                :placeholder="$t('label.please_select')"
                :label="$t('label.dealership')" />
            <StyledDropdown
                v-model="form.certificate_id"
                name="certificate_id"
                item-title="certificate_number"
                item-value="id"
                :options="filteredCertificates"
                :placeholder="$t('label.please_select')"
                :label="$t('label.certificate')" />
            <StyledVDatepicker
                v-model="form.claim_date"
                :label="$t('label.claim_date')"
                name="claim_date" />
            <StyledDropdown
                v-model="form.claimant"
                name="claimant"
                item-title="full_name"
                item-value="id"
                :options="buyers"
                :placeholder="$t('label.please_select')"
                :label="$t('label.claimant')"
                @input="onSelectClaimant" />
            <StyledCurrency
                v-model="form.amount"
                color="primary"
                placeholder="0.00"
                name="amount"
                :label="$t('label.amount')" />
            <StyledDropdown
                v-model="form.type"
                item-title="name"
                item-value="value"
                :options="types"
                name="type"
                :placeholder="$t('label.please_select')"
                :label="$t('label.type')" />
            <StyledInput
                v-model="form.work_order_number"
                name="work_order_number"
                :placeholder="$t('label.work_order')"
                :label="$t('label.work_order')" />
            <StyledMask
                v-model="form.contact_number"
                :mask="['###-###-####']"
                :label="$t('label.contact_number')"
                name="contact_number"
                placeholder="###-###-####" />
            <StyledInput
                v-model="form.contact_email"
                name="contact_email"
                :label="$t('label.email')"
                :placeholder="$t('label.email')" />
            <StyledTextArea
                v-model="form.description"
                :placeholder="$t('label.description')"
                :label="$t('label.description')"
                class="col-span-2"
                name="description" />
        </div>
    </form>

    <template #footer>
        <StyledSaveButton modal @save="save" />
    </template>
</ModalWrapper>
</template>
