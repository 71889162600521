const findProductField = (product, productFieldName) => {
    return product
        .product_fields
        .find(field => {
            return field.name == productFieldName;
        });
};

const findCertificateProductFieldIndex = (form, productFieldName, productFieldOptionName = null) => {
    return form
        .certificate_product_fields
        .findIndex(field => {
            if (productFieldOptionName) {
                return field.product_field_name == productFieldName && field.product_field_option_name == productFieldOptionName;
            } else {
                return field.product_field_name == productFieldName;
            }
        });
};

const findCertificateProduct = (certificate, form, key) => {
    const split = key.split('.');
    const productName = split[0];
    const sectionName = split.length > 1 ? split[1] : null;
    const optionName = split.length > 2 ? split[2] : null;
    if (!sectionName) {
        const obj = certificate
            .certificate_products
            .find(product => {
                return product.name == productName;
            });
        return {
            type: 'product',
            object: obj
        };
    }

    if (productName == form.name) {
        const obj = form
            .certificate_product_fields
            .find(field => {
                if (optionName) {
                    return field.product_field_name == sectionName && field.product_field_option_name == optionName;
                } else {
                    return field.product_field_name == sectionName;
                }
            });
        return {
            type: 'product_field',
            object: obj
        };
    } else {
        const certificateProduct = certificate.certificate_products.find(p => p.name == productName);
        if (certificateProduct) {
            const obj = certificateProduct
                .certificate_product_fields
                .find(field => {
                    if (optionName) {
                        return field.product_field_name == sectionName && field.product_field_option_name == optionName;
                    } else {
                        return field.product_field_name == sectionName;
                    }
                });
            return {
                type: 'product_field',
                object: obj
            };
        }
    }
    return null;
};

const addCertificateProductField = (form, productFieldName, productFieldOptionName, productFieldValue, productFieldId, productId) => {
    form.certificate_product_fields.push({
        product_field_name: productFieldName,
        product_field_option_name: productFieldOptionName,
        product_field_value: productFieldValue,
        product_field_id: productFieldId,
        product_id: productId,
        is_remittance_fee_applicable: false,
        remittance_fee_total: 0
    });
};

const valueCombinedRules = {
    // If "Protection.Interior.Environmental Interior" was selected, then "Protection.Interior.Primary Fabric/Leather/Vinyl" needs to be selected automatically
    'Protection.Interior.Environmental Interior': ['Protection.Interior.Primary Fabric/Leather/Vinyl'],
    'Protection.Exterior.Environmental Paint': ['Protection.Exterior.Primary Paint'],
    'Protection.Rust.Environmental Surface Rust': ['Protection.Rust.Rust Inhibitor Spray'],
    'Autoguard.Term.*': ['Autoguard.Exterior.Dents & Dings', 'Autoguard.Exterior.Scratches', 'Autoguard.Interior.Cuts, Tears & Burns'],
    'Antitheft.Term.*': ['Antitheft.Marking.Body Panel Label'],
    'Tire and Rim.Term.*': [
        'Tire and Rim.Type of Tires.Standard Tires',
        'Tire and Rim.Optional Rim Service.Rim Damage'
    ],
    'Tire and Rim.Tire Size.*': [
        // functional handler
        (value, form, product, section, option) => {
            const tireSize = parseInt(option.name);
            const isTireOverSize = tireSize > 20;
            updateCertificateProductField(isTireOverSize, form, product, 'Tire and Rim.Type of Tires.Oversize Tires');
        }
    ]
};

const handleProductRules = (value, form, product, section, option = null) => {
    let key = `${product.name}.${section.name}${option ? `.${option.name}` : ''}`;
    if (!(key in valueCombinedRules)) {
        key = `${product.name}.${section.name}.*`;
        if (!(key in valueCombinedRules)) {
            return;
        }
    }

    const combinedFields = valueCombinedRules[key];
    for (const combinedField of combinedFields) {
        if (typeof combinedField === 'function') {
            combinedField(value, form, product, section, option);
            continue;
        }
        updateCertificateProductField(value, form, product, combinedField);
    }
};

const updateCertificateProductField = (value, form, product, dottedFieldName) => {
    const split = dottedFieldName.split('.');
    const sectionName = split[1];
    const productField = findProductField(product, sectionName);
    const optionName = split.length > 2 ? split[2] : null;
    const index = findCertificateProductFieldIndex(form, sectionName, optionName);
    if (value) {
        if (index === -1) {
            addCertificateProductField(form, sectionName, optionName, !!value, productField.id, product.id);
        } else if (value) {
            form.certificate_product_fields[index].product_field_value = !!value;
        }
    } else {
        if (index !== -1) {
            form.certificate_product_fields.splice(index, 1);
        }
    }
};

const ineligibleRules = {
    // If Protection.Exterior.Environmental Paint was selected, then Protection.Exterior.Ceramic Coating cannot be selected
    'Protection.Exterior.Ceramic Coating': ['Protection.Exterior.Environmental Paint'],
    'Protection.Exterior.Primary Paint': ['Protection.Exterior.Environmental Paint'],
    'Protection.Interior.Primary Fabric/Leather/Vinyl': ['Protection.Interior.Environmental Interior'],
    'Protection.Interior.Rips, Tears & Burns': ['Autoguard'],
    'Autoguard.Exterior.Dents & Dings': ['Autoguard.Term'],
    'Autoguard.Exterior.Scratches': ['Autoguard.Term'],
    'Autoguard.Interior.Cuts, Tears & Burns': ['Autoguard.Term'],
    'Protection.Rust.Rust Inhibitor Spray': ['Protection.Rust.Environmental Surface Rust'],
    'Antitheft.Marking.Body Panel Label': ['Antitheft.Term'],
    'Tire and Rim.Type of Tires.Standard Tires': ['Tire and Rim.Term'],
    'Tire and Rim.Optional Rim Service.Rim Damage': ['Tire and Rim.Term'],
    'Tire and Rim.Type of Tires.Oversize Tires': ['Tire and Rim.Term']
};

const isIneligible = (certificate, form, product, section, option = null) => {
    const key = `${product.name}.${section.name}${option ? `.${option.name}` : ''}`;
    const ineligibleProductFields = ineligibleRules[key];
    if (ineligibleProductFields) {
        for (const productField of ineligibleProductFields) {
            if (typeof productField === 'function') {
                const result = productField(certificate, form, product, section, option);
                if (result) {
                    return result;
                }
                continue;
            }
            const certificateProduct = findCertificateProduct(certificate, form, productField);
            if (certificateProduct && certificateProduct.object) {
                if (certificateProduct.type === 'product') {
                    return certificateProduct.object.certificate_product_fields.length > 0;
                } else {
                    return !!certificateProduct.object;
                }
            }
        }
    }
    return false;
};

const isProductFieldSelectable = (certificate, field, product = null) => {
    if (!certificate.bundle || !certificate.bundle.bundle_products || (product && ['Antitheft', 'Protection'].includes(product.name))) {
        return true;
    }

    return certificate.bundle.bundle_products.some(bundleProduct =>
        bundleProduct.bundle_product_fields.some(bundleProductField =>
            bundleProductField.product_field_id === field.id && bundleProductField.is_selectable
        )
    );
};

export { handleProductRules, isIneligible, isProductFieldSelectable };
