<script setup >
import axios from 'axios';
import LoginContainer from '@/components/Auth/LoginContainer';
import PasswordRequirementsList from '@/components/Auth/PasswordRequirementsList.vue';
import { ref, onMounted } from 'vue';
import { router } from '@inertiajs/vue3';

const props = defineProps({
    errors: {
        type: Object,
        default: _ => ({})
    },
    set: {
        type: Boolean,
        default: false
    },
    token: {
        type: String,
        default: ''
    }
});

const user = ref({
    email: '',
    password: '',
    passwordConfirm: '',
    token: props.token,
});

function getError(selector) {
    const error = props.errors[selector];
    return error || '';
};

onMounted(() => {
    const urlParams = new URLSearchParams(window.location.search);
    user.value.email = urlParams.get('email');
});

async function reset() {
    try {
        await axios.get('/sanctum/csrf-cookie');
        await router.post('/password-reset', {
            token: user.value.token,
            email: user.value.email,
            password: user.value.password,
            password_confirmation: user.value.passwordConfirm,
            set: props.set,
        });
    } catch (e) {
        //
    }
};
</script>
<template>
<LoginContainer>
    <template #title>{{ set ? 'Setup Your Password' : 'Reset Your Password' }}</template>
    <form @submit.prevent="reset()">
        <div class="grid grid-cols-1 space-y-2 my-5">
            <StyledInput
                v-model="user.email"
                name="email"
                type="email"
                disabled
                placeholder="Email"
                label="Confirm Email"
                :error-message="getError('email')" />
            <StyledPassword
                v-model="user.password"
                name="password"
                placeholder="******************"
                label="Password"
                :error-message="getError('password')" />
            <StyledPassword
                v-model="user.passwordConfirm"
                name="password-confirm"
                placeholder="******************"
                label="Password Confirmation"
                :error-message="getError('password')" />
            <PasswordRequirementsList :password="user.password" />
        </div>
        <StyledButton
            :full="true"
            type="submit">
            {{ set ? 'Save' : 'Reset' }}
        </StyledButton>
    </form>
</LoginContainer>
</template>

