import { ref } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';

export const useLenderStore = defineStore('lender', () => {
    const all = ref(null);
    const locale = ref('en');

    const actions = {
        getLenderTypeString(lender) {
            return lender.type ? lender.type.charAt(0).toUpperCase() + lender.type.slice(1) : '';
        },
        searchLenders: (params) => axios.get('/api/v1/lenders', { params }),
        storeLender: (params) => axios.post('/api/v1/lenders', params),
        updateLender: (params) => axios.patch('/api/v1/lenders/' + params.hash, params),
        destroyLender: (params) => axios.delete('/api/v1/lenders/' + params.hash),
    };

    return {
        all,
        locale,
        ...actions,
    };
});
