<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { computed, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useUserStore } from '@/stores/user';
import { useOptionsStore } from '@/stores/options';
import { handleError } from '@/mixins/ErrorHandlingMixin';

const props = defineProps({
    user: {
        type: Object,
        default: () => ({
            role: null,
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            is_active: true,
            dealership_ids: []
        })
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const emit = defineEmits(['close-modal']);
const form = ref({ ...props.user });

const toast = useToast();

const { roles, dealerships } = useOptionsStore();
const userStore = useUserStore();
const { storeUser, updateUser, userProfile } = userStore;

const getRoles = computed(() => {
    if (userProfile.hasRole('dealership_manager')) {
        return roles.filter(role => role.name === 'fsm' || role.name === 'dealership_manager');
    }
    return roles;
});

const getDealerships = computed(() => {
    if (userProfile.hasRole('dealership_manager')) {
        return dealerships.filter(dealership => userProfile.dealerships.some(userDealership => userDealership.id === dealership.id));
    }
    return dealerships;
});

if (props.user.hash && props.user.role?.value) {
    const matchedRole = roles.find(role => role.name === props.user.role.value);
    if (matchedRole) form.value.role = matchedRole.name;
}

if (props.user.dealerships) {
    form.value.dealership_ids = props.user.dealerships.map(dealership => dealership.id);
}

const save = async () => {
    try {
        const action = props.mode === 'Create' ? storeUser : updateUser;
        const toastMessage = props.mode === 'Create' ? 'User Created' : 'User Updated';

        await action({
            ...form.value,
            hash: props.user.hash,
        });

        toast.success(toastMessage);
        emit('close-modal');
    } catch (error) {
        handleError(error);
    }
};

const hideSetPassword = () => {
    form.value.set_password = false;
    form.value.password = '';
    form.value.password_confirmation = '';
};

const hideSendPassword = () => {
    form.value.send_password = false;
};
</script>
<template>
<ModalWrapper>
    <template #header>{{ mode }} User</template>
    <template #subheader>Create or edit a user</template>

    <form @submit.prevent="save">
        <div class="grid md:grid-cols-2 gap-x-4 gap-y-3">
            <StyledInput
                v-model="form.first_name"
                name="first_name"
                placeholder="First Name"
                label="First Name" />
            <StyledInput
                v-model="form.last_name"
                placeholder="Last Name"
                name="last_name"
                label="Last Name" />
            <StyledInput
                v-model="form.email"
                name="email"
                placeholder="example@mail.com"
                label="Email" />
            <StyledMask
                v-model="form.phone_number"
                :mask="['###-###-####']"
                :label="$t('label.phone_number')"
                name="phone_number"
                placeholder="###-###-####" />
            <StyledDropdown
                v-model="form.role"
                item-title="display_name"
                item-value="name"
                :options="getRoles"
                name="role"
                placeholder="Please Select"
                label="Account Type" />
            <StyledDropdown
                v-if="form.role === 'fsm' || form.role === 'dealership_manager'"
                v-model="form.dealership_ids"
                item-title="name"
                item-value="id"
                :options="getDealerships"
                multiple
                name="dealership_ids"
                placeholder="Please Select"
                label="Dealership Access" />
            <div v-if="form.role === 'fsm' || form.role === 'dealership_manager'" />
            <StyledRoundSwitch
                v-model="form.is_active"
                label="Is Active"
                inset
                name="is_active" />
            <StyledRoundSwitch
                v-if="!form.set_password"
                v-model="form.send_password"
                label="Email Password Setup Request"
                inset
                name="send_password"
                @input="hideSetPassword" />
            <StyledRoundSwitch
                v-if="!form.send_password"
                v-model="form.set_password"
                :label="mode === 'Edit' ? 'Reset Password' : 'Set Password'"
                inset
                name="set_password"
                @input="hideSendPassword" />
        </div>

        <div v-if="form.set_password" class="grid md:grid-cols-2 gap-x-4">
            <StyledPassword
                v-model="form.password"
                name="password"
                placeholder="********"
                label="Password" />
            <StyledPassword
                v-model="form.password_confirmation"
                name="password_confirmation"
                placeholder="********"
                label="Password Confirmation" />
        </div>
    </form>

    <template #footer>
        <StyledSaveButton modal @save="save" />
    </template>
</ModalWrapper>
</template>
